import { Table } from 'antd'
import { IDeviceWithLiteGroupInfo, ILite360Group } from '../../constants/device'
import React, { useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table/interface'
import { GetComponentProps } from 'rc-table/lib/interface'
import {useSelector, }  from 'react-redux'
import { IJob, getDownloadJobs } from 'api/jobs'

interface IJobGroupTableProps {
  job: IJob
  scroll?: { x: number }
  highlightRowId?: string
  onRow?: GetComponentProps<IJob>
  getColumns?: () => ColumnsType<IJob>
}

export const JobGroupTable = (props: IJobGroupTableProps) => {
  const { job: { groupJobID }, getColumns, scroll, onRow } = props;
  const { id: cityID } = useSelector(({ city: { current } }) => current)
  
  const [list, setList] = useState(null)
  const [error,setError] = useState(null)

  useEffect(() => {
    fetchJobGroup()
  }, [groupJobID])

  const fetchJobGroup = () => {
    if (!groupJobID) return
    getDownloadJobs({
      cityID,
      filters: {
        groupJobID: [groupJobID],
      },
      pageIndex: 1,
      pageSize: 10
    })
      .then(res => {
        const { jobs } = res;
        jobs.sort((job1, job2) => job1.photoDirection - job2.photoDirection)
        setList(jobs)
      })
      .catch((error) => {
        setError( error)
      })
  }
  
  if (!list) {
    return <div>loading...</div>
  }
  if (error) {
    return <div>Fetch group info failed {error.toString()}</div>
  }

  return (
    <Table
      tableLayout='fixed'
      showHeader={false}
      rowKey={(record) => record.jobID}
      dataSource={list}
      columns={getColumns()}
      pagination={false}
      onRow={onRow}
      rowClassName={(record) => {
        if (record.photoDirection === 0) {
          return 'lite360-highlight-row'
        }
      }}
      scroll={scroll}
    />)
}
