import { DeviceType } from '../../constants/device'
import { IChoicesFilter, SupportFilterType } from './CameraFilter.interface'

export const deviceFilter: IChoicesFilter<DeviceType> = {
  title: "Device Type",
  key: "deviceType",
  type: SupportFilterType.MultipleChoices,
  sections: [
    {
      value: DeviceType.TwoW,
      name: "2W"
    },
    {
      value: DeviceType.FourW,
      name: "4W"
    },
    {
      value:DeviceType.Lite360Master,
      name:'360Lite(Master)'
    },
    {
      value:DeviceType.Lite360Slave,
      name:'360Lite(Slave)'
    },
    // {
    //   value: DeviceType.Lite360Master,
    //   name: "360-Lite (Master)"
    // },
    // {
    //   value: DeviceType.Lite360Slave,
    //   name: "360-Lite (Slave)"
    // }
  ]
};


