import { Menu, Space } from "antd";
import { InfoCircleOutlined, SettingOutlined, UploadOutlined } from "@ant-design/icons";
import React from "react";
import { ContextMenu } from "../../components/context-menu";
import { CameraDirectoryAction } from "../../constants/device";
import { GPSTrace } from "../../components/icon";

export type CameraContextMenuAnchor = { x: number, y: number }

export interface ICameraContextMenuProps {
  visible: boolean;
  anchor: CameraContextMenuAnchor,
  onDismiss: () => void
  onClick: (action: CameraDirectoryAction) => void;
  excludeActions?: CameraDirectoryAction[]
}

type MenuItem = {
  icon: React.JSXElementConstructor<any>,
  action: CameraDirectoryAction
  title: string
}

const menuItems: MenuItem[] = [
  {
    title: "View Details",
    icon: InfoCircleOutlined,
    action: CameraDirectoryAction.ViewDetails
  },
  {
    title: "Change Settings",
    icon: SettingOutlined,
    action: CameraDirectoryAction.ChangeSettings
  },
  {
    title: "View GPS Trace",
    icon: GPSTrace,
    action: CameraDirectoryAction.ViewTracks
  },
  {
    title: "Create upload Task",
    icon: UploadOutlined,
    action: CameraDirectoryAction.CreateTask
  }
];

export function CameraContextMenu(props: ICameraContextMenuProps) {
  const { onDismiss, visible, anchor, excludeActions = [], onClick } = props;
  return (
    <ContextMenu
    visible={visible}
    anchor={anchor}
    onDissmiss={onDismiss}
    >
    <Menu selectedKeys={[]}>
      {menuItems.map((menuItem) => {
        const Icon = menuItem.icon;
        const isExcluded = excludeActions.indexOf(menuItem.action) > -1;
        if (isExcluded) {
          return null;
        }
        return (
          <Menu.Item key={menuItem.action} onClick={()=>onClick(menuItem.action)} >
            <Space size={8}>
              <Icon/>
                {menuItem.title}
              </Space>
          </Menu.Item>
        );
      })}
    </Menu>
  </ContextMenu>
  );
}
