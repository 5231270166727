export enum SupportFilterType {
  SingleChoice,
  MultipleChoices,
}

export type ChoicesFilter = SupportFilterType.SingleChoice | SupportFilterType.MultipleChoices

export interface IFilterItem<T> {
  title: string
  key: string;
  type: T
}


type IChoiceSection<V> = {
  value: V
  name: string
}

export interface IChoicesFilter<V> extends IFilterItem<ChoicesFilter> {
  sections: IChoiceSection<V>[]
}

