import { Table } from 'antd'
import { IDevice, IDeviceWithLiteGroupInfo, ILite360Group, ILite360GroupDeviceMember } from '../../constants/device'
import React, { useEffect, useState } from 'react'
import { fetchDevicesInGroup, getDeviceList } from '../../api/devices'
import { ColumnsType } from 'antd/lib/table/interface'
import { GetComponentProps } from 'rc-table/lib/interface'
import {useSelector}  from 'react-redux'

interface ILiteGroupTableProps {
  device: IDevice
  scroll?: { x: number }
  highlightRowId?: string
  onRow?: GetComponentProps<IDevice>
  getColumns: () => ColumnsType<IDevice>
}

interface ILite360GroupForTable extends ILite360Group {
  devices: IDeviceWithLiteGroupInfo[]
}

interface ILiteGroupTableState {
  liteGroup?: ILite360GroupForTable
  error?: Error
}

function combineDeviceInfo(members: ILite360GroupDeviceMember[], devices: IDevice[]): IDeviceWithLiteGroupInfo[] {
  members.sort((a, b) => (a.direction - b.direction))
  const deviceDic: { [key: string]: IDevice } = {}
  devices.forEach((d) => deviceDic[d.deviceID] = d)
  return members.map((member) => ({ ...member, ...deviceDic[member.deviceID] }))
}

export const LiteGroupTable = (props: ILiteGroupTableProps) => {

  const [liteGroup, setLiteGroup] = useState(null)
  const [error,setError] = useState(null)
  const {directory:{fetchDeviceList}} = useSelector(({loading:{effects:{directory}}})=>({directory}))
  useEffect(() => {
    if(fetchDeviceList) return
    fetchLiteGroup()
  }, [props?.device?.deviceID,fetchDeviceList])

  const fetchLiteGroup = () => {
    const { device } = props
    if (!device.groupID) {
      return
    }
    const { groupID, cityID } = device
    fetchDevicesInGroup(groupID)
      .then((liteGroup) => {
        getDeviceList({ groupID, page: 1, pageSize: 10 }).then((res) => {
          const deviceList = res.results as IDevice[]
          const devices = combineDeviceInfo(liteGroup.devices, deviceList)
          setLiteGroup({ ...liteGroup, devices } )
        })
      })
      .catch((error) => {
        setError( error)
      })
  }
  const { getColumns, scroll, onRow, highlightRowId } = props
  if (!liteGroup) {
    return <div>loading...</div>
  }
  if (error) {
    return <div>Fetch group info failed {error.toString()}</div>
  }
  return (
    <Table
      className='directory-table'
      tableLayout='fixed'
      showHeader={false}
      rowKey={(record) => `lite-group-member-${record.deviceID}-`}
      dataSource={liteGroup.devices}
      columns={getColumns()}
      pagination={false}
      onRow={onRow}
      rowClassName={(record) => {
        if (record.deviceID === highlightRowId) {
          return 'lite360-highlight-row'
        }
      }}
      scroll={scroll}
    />)
}
