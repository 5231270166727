import { api } from './index'

export interface DongleHardware {
  id: number
  deviceID: string
  IMSI: string
  IMEI: string
  wifi_mac_addr: string
  ble_mac_addr: string
  created_at: string
}

export interface IDongleHardwareResponse {
  hardware_infos: DongleHardware[]
}

export interface IDongleHardwareQueryParams {
  deviceID: string
  page: number
  pageSize: number
}

export function getDongleHardware(params: IDongleHardwareQueryParams): Promise<IDongleHardwareResponse> {
  const { deviceID, ...restParams } = params
  return api.get(`/dongle/${deviceID}/hardware_infos`)
}

export interface IDongleReportQueryParams {
  deviceID: string
  page: number
  pageSize: number
}

export interface DongleReport {
  id: number
  deviceID: string
  lon?: number
  lat?: number
  longitude: number
  latitude: number
  speed: number
  oil_volumn: number
  mileage: number
  err_code: number
  vehicle_malfunction_code: number
  create_at: string
}

export interface IDongleReportResponse {
  device_reports: DongleReport[]
}

export function getDongleReports(params: IDongleReportQueryParams): Promise<IDongleReportResponse> {
  const { deviceID, ...restParams } = params
  return api.get(`/dongle/${deviceID}/device_reports`, { params: restParams })
}

