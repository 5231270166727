import { Tooltip } from "antd";
import { InfoCircleOutlined, SettingOutlined, UploadOutlined } from "@ant-design/icons";
import React from "react";
import { GPSTrace } from "../icon";
import { CameraDirectoryAction } from "../../constants/device";

interface DeviceActionsProps {
  actions: CameraDirectoryAction[]
  onAction: (action: CameraDirectoryAction) => void
}

type ActionItem = { icon: React.JSXElementConstructor<any>, title: string } | null

const SupportedActionItems: { [key: string]: ActionItem } = {
  [CameraDirectoryAction.ViewDetails]: { icon: InfoCircleOutlined, title: "View Details" },
  [CameraDirectoryAction.ChangeSettings]: { icon: SettingOutlined, title: "Change Settings" },
  [CameraDirectoryAction.CreateTask]: { icon: UploadOutlined, title: "Create Upload Task" },
  [CameraDirectoryAction.ViewTracks]: { icon: GPSTrace, title: "Show GPS traces" }
};

export function DeviceActions(props: DeviceActionsProps) {
  const { actions, onAction } = props;
  return <>
    {
      actions.map((action, index) => {
        const actionConfig = SupportedActionItems[action];
        if (!actionConfig) {
          return <div key={index}
                      style={{ fontSize: 20, margin: "0 5px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onAction(action);
                      }}>{action}</div>;
        }
        const IconComponent = actionConfig.icon;
        return <Tooltip key={index} title={actionConfig.title}>
          <IconComponent
            onClick={(e) => {
              e.stopPropagation();
              onAction(action);
            }}
            style={{ fontSize: 20, margin: "0 5px" }}
          /></Tooltip>;
      })
    }
  </>;
}
